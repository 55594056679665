var TOP = TOP || {};

TOP.main = {
  header        : $('#l_header'),
  conversion    : $('#l_conversion'),

  video         : $('#js_video'),
  videoContainer: $('#js_video__container'),
  news          : $('#js_news'),
  typography    : $('#js_typography'),
  init: function() {
    this.video.get(0).loop = true;
    this.video.get(0).muted = true;

    var timer = new Date();
    var flag1 = false;
    var flag2 = false;
    var flag3 = false;
    var flag4 = false;

    var que = () => {
      var now = new Date();

      // ①背景動画(黒からフェードイン)
      if(!flag1) {
        // console.log('①背景動画(黒からフェードイン)');
        flag1 = true;

        this.video.get(0).play();
        this.videoContainer.velocity('fadeIn', { duration: 100 });
      }

      // ②「INNOVATION HR」
      if((now - timer) > 0 && !flag2) {
        flag2 = true;
        // console.log('②「INNOVATION HR」');
        this.typography.css({opacity: 0}).velocity({ opacity: 1}, { duration: 0 });
      }

      // ③点アニメーションスタート
      if((now - timer) > 100 && !flag3) {
        console.log(flag3);
        flag3 = true;

        var ie_or_edge = false;
        var userAgent = window.navigator.userAgent.toLowerCase();

        if(userAgent.indexOf('msie') != -1 || userAgent.indexOf('trident') != -1 || userAgent.indexOf('edge') != -1) {
          ie_or_edge = true;
        }

        if(ie_or_edge) {
          // IE・Engeの場合
          setTimeout(function() {
            typographySlider();
          }, 1400);

        } else {
          // その他のブラウザ
          var script = document.createElement('script');
          script.src = '/assets/js/scripts.min.js';
          document.body.appendChild(script);
        }
      }


      // ④ヘッダー・1行ニュースが出る
      if((now - timer) > 3500 && !flag4) {
        // console.log('④ヘッダー・1行ニュースが出る');
        flag4 = true;


        $('body').addClass('ANIMATION_LOADED')
        // this.header.velocity({top: 0}, { duration: 1000});
        // this.news.velocity({bottom: 0, opacity: 1}, { duration: 1000});
      }

      if(!flag1 || !flag2 || !flag3 || !flag4) {
        requestAnimationFrame(que);
      }




    }
    que();


    // canvasアニメーションの代わりに画像でスライドさせる
    function typographySlider() {
      var $slider = $('#js_slider');
      var images = [
        '/wp-content/themes/newgrads/_asset/images/top/dot-artificial-intelligence.png',
        '/wp-content/themes/newgrads/_asset/images/top/dot-big-data.png',
        '/wp-content/themes/newgrads/_asset/images/top/dot-robostic-process-automation.png',
        // '/wp-content/themes/newgrads/_asset/images/top/dot-marketing-automation.png',
      ];

      // 画像をDOMに追加
      var slides = [];
      for (var i = 0; i < images.length; i++) {
        slides.push( $('<img>').attr('src', images[i])[0] );
      }
      var $slides = $(slides);

      $slides.hide();
      $slider.append( $slides );

      // options
      var interval = 4000;
      var timer = new Date();
      var length = images.length;
      var que = 0;
      var duradion = 800;

      // interval
      var slide = () => {
        var now = new Date();

        if(now - timer > interval) {
          timer = now;
          $slides.eq(que).velocity('fadeOut', { duration: duradion, display: 'block' });

          // 次のスライド番号を取得する
          que = (que + 1 == length) ? 1: que + 1;
          $slides.eq(que).velocity('fadeIn', { duration: duradion, display: 'block' });
        }

        requestAnimationFrame(slide);
      }

      $slides.eq(0).velocity('fadeIn', { duration: duradion, display: 'block' });
      slide();
    }


    // メインビジュアルエリアの可視判定
    var hero = document.getElementById('l_hero');
    const callback = (entries, observer) => {
      entries.forEach(entry => {
        const el = entry.target;

        /*if(entry.isIntersecting) {
          this.video.get(0).play();
          this.conversion.css({bottom: ''});
        } else {
          this.video.get(0).pause();
          this.conversion.css({bottom: 0});
        }*/
      });
    }
    var options = {};
    var observer = new IntersectionObserver(callback, options);
    observer.observe(hero);
  }
};

/**
 *
 *
 */
TOP.fullpage = {
  init: function() {

    var ua = window.navigator.userAgent.toLowerCase();
    var is_ie11 = (ua.indexOf('trident/7') !== -1)?true:false;
    if (is_ie11) {
      return;
    }

    var _ANCHOR_PREFIX  = 'scene';
    var $body = $('body');

    // 表示する画面の配列
    var $fullpagePanels = $('.uq_flip_wrap .uq_flip');
    // アンカーの生成
    var anchors = [];
    for(var i=0,max=$fullpagePanels.length; i<max; i++) {
      anchors.push(_ANCHOR_PREFIX + (i+1));
    }
    var isSkipLeaveAnimation = (window.location.hash && (window.location.hash != '#' + _ANCHOR_PREFIX + '1'))?true:false;

    var $fullpage = $('.uq_flip_wrap').fullpage({

      menu: false,
      lockAnchors: false,
      anchors: anchors,
      navigation: false,
      licenseKey: 'B35CE406-69314428-84842222-A341B952',
      // navigationPosition: 'right',
      // navigationTooltips: anchors,
      showActiveTooltip: false,
      slidesNavigation: false,
      // slidesNavPosition: 'bottom',

      //Scrolling
      css3: true,
      scrollingSpeed: 600,
      autoScrolling: true,
      fitToSection: true,
      fitToSectionDelay: 0,
      scrollBar: false,
      easing: 'easeInOutCubic',
      easingcss3: 'linear',
      loopBottom: false,
      loopTop: false,
      loopHorizontal: true,
      continuousVertical: false,
      continuousHorizontal: false,
      scrollHorizontally: false,
      interlockedSlides: false,
      dragAndMove: false,
      offsetSections: false,
      resetSliders: false,
      fadingEffect: false,
      scrollOverflow: true,
      scrollOverflowReset: false,
      scrollOverflowOptions: null,
      touchSensitivity: 15,
      normalScrollElements: '#l_navigation',
      normalScrollElementTouchThreshold: 5,
      bigSectionsDestination: null,

      //Accessibility
      keyboardScrolling: true,
      animateAnchor: false,
      recordHistory: true,

      //Design
      // controlArrows: true,
      verticalCentered: true,
      paddingTop: 0,
      paddingBottom: 0,
      // fixedElements: '.l_header',
      responsiveWidth: 0,
      responsiveHeight: 0,
      responsiveSlides: false,
      parallax: false,
      parallaxOptions: {type: 'reveal', percentage: 0, property: 'translate'},

      //Custom selectors
      sectionSelector: '.uq_flip',
      lazyLoading: true,

      // events
      onLeave: function(index, nextIndex, direction){
        console.log('onLeave');
        // sceneIndex = nextIndex;

        console.log('out[' + index.index + '] in[' + nextIndex.index + '] -> ' + direction);

        // シーンの移り変わりアニメーション
        if (!isSkipLeaveAnimation) {
          $('.fp-section').addClass('js-flip-move');
          setTimeout(function() {
            $('.fp-section').removeClass('js-flip-move');
          }, 300);

          // flipの中の表示アニメーション
          $fullpagePanels.eq(index.index).find('.js-flip-elem').removeClass('is-ready');
          $fullpagePanels.eq(nextIndex.index).find('.js-flip-elem').addClass('is-ready');
        }
        isSkipLeaveAnimation = false;


        // ヘッダーの背景色制御
        if (nextIndex.index == 0) {
          $body.removeClass('VIEW_CONTENTS');
        } else {
          $body.addClass('VIEW_CONTENTS');
        }

        // フッターのCVボタン（SP版）の表示制御
        if ((index.index == 0) && (nextIndex.index == 1)) {
          console.log('second panel show');
          $('.l_floating').css({'bottom': 0});
        } else if ((index.index == 1) && (nextIndex.index == 0)) {
          console.log('second panel hide');
          $('.l_floating').css({'bottom': '-100px'});
        }


      },

      afterLoad: function(anchorLink, index){
        console.log('afterLoad');

        $fullpagePanels.eq(index.index).find('.js-flip-elem').each(function(idx) {
          var $this = $(this);
          setTimeout(function() {
            $this.removeClass('is-ready');
          }, idx * 100);
        });
      },

      afterRender: function(){
        console.log('afterRender');
      },

      afterResize: function(){
        console.log('afterResize');

        setTimeout(function() {
          $.fn.fullpage.reBuild();
        }, 500);
      }

    });


    // ハンバーガーメニューへの処理追加
    $('.js_navigationToggle').data('callback-func', function(is_open) {

// console.log('call - naviToggle callback [' + is_open + ']');
      // ハンバーガーメニューOPEN時、fullpage.jsのスクロールをOFFにする
      if (is_open) {
        // $.fn.fullpage.setAllowScrolling(false);
        $.fn.fullpage.setKeyboardScrolling(false);
      // ハンバーガーメニューCLOSE時、fullpage.jsのスクロールをOONにする
      } else {
        // $.fn.fullpage.setAllowScrolling(true);
        $.fn.fullpage.setKeyboardScrolling(true);
      }
    });
    // スマホ時のフッターアコーディオンへの処理追加
    $('.l_sitemap__titlePlus').data('callback-func', function() {
      $.fn.fullpage.reBuild();
    });

  }
};

window.addEventListener('load', function() {
  TOP.main.init();
  //TOP.fullpage.init();
});
